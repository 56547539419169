<template>
	<div class="page-content">
		<div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<el-form-item label="名称" prop="username">
					<el-input v-model="searchForm.name" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-right: auto;">
					<div class="fill-btn" @click="storeList">查询</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;" v-if="user_type==1">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" @selection-change="handleSelectionChange"
				cell-class-name="text-center" header-cell-class-name="header-row-class-name">
				<el-table-column prop="id" label="ID" min-width="120px"></el-table-column>
				<el-table-column prop="name" label="店铺名称" min-width="120px"></el-table-column>
				<el-table-column prop="address" label="地址" min-width="120px"></el-table-column>
				<el-table-column prop="tel" label="电话" min-width="120px"></el-table-column>
				<el-table-column prop="created_at" min-width="160px" label="创建时间"></el-table-column>
				<el-table-column width="230" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
								@click="handEditShow(row)"></el-button>
							<el-button class="el-icon-delete cur-p" type="primary" size="mini" @click="teacherDel(row)">
							</el-button>
							<el-button class=" cur-p" type="primary" size="mini" @click="storeCode(row)">下载二维码
							</el-button>

						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="创建" :visible.sync="showAdd" width="700px">
			<div>
				<el-form ref="form" :model="addTeacher" label-width="70px">
					<el-form-item label="店铺名称">
						<el-input v-model="addTeacher.name"></el-input>
					</el-form-item>
					<el-form-item label="归属用户">
						<el-select v-model="addTeacher.user_id" clearable placeholder="类型">
							<el-option v-for="item in storeUserList" :key="item.id" :label="item.username"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="店铺封面">
						<el-upload class="avatar-uploader" action="/admin/upload" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="addTeacher.images" :src="addTeacher.images" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div class="el-upload__tip" slot="tip">336*180</div>
						</el-upload>
					</el-form-item>


					<el-form-item label="店铺电话">
						<el-input v-model="addTeacher.tel"></el-input>
					</el-form-item>


					<el-form-item label="营业时间">
						<el-col :span="11">
							<el-time-picker placeholder="开始时间" value-format="HH:mm:ss" v-model="addTeacher.start_time"
								style="width: 100%;"></el-time-picker>
						</el-col>

						<el-col :span="11">
							<el-time-picker placeholder="结束时间" value-format="HH:mm:ss" v-model="addTeacher.end_time"
								style="width: 100%;"></el-time-picker>
						</el-col>
					</el-form-item>
					<el-form-item label="店铺环境">
						<el-upload action="/admin/upload" list-type="picture-card"
							:on-preview="handlePictureCardPreview" :on-change="handleChange" :on-remove="handleRemove">
							<i class="el-icon-plus"></i>
							<div class="el-upload__tip" slot="tip">351*250</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="店铺介绍">
						<RichText v-model="addTeacher.introduce"></RichText>
					</el-form-item>

					<el-form-item label="店铺地址">
						<el-input v-model="address" @input="getMap(address)"></el-input>
					</el-form-item>
					<!-- <div>
						{{address}}---{{longitude}}，{{latitude}}
					</div> -->
					<el-form-item label="地图">
						<template>
							<!-- :center="address" -->
							<baidu-map class="bm-view" @ready="mapReady" :center="{lng: longitude, lat: latitude}" :zoom="zoomLevel"
								:scroll-wheel-zoom="true" @click="clickHandler" @zoomend="handleZoom">
								<bm-marker :position="{lng: longitude, lat:latitude}"></bm-marker>
								<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
							</baidu-map>
						</template>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="editing" @click="addTeachers" style="margin: 10px auto">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="编辑" :visible.sync="showEdit" width="700px">
			<div>
				<el-form ref="form" :model="editForm" label-width="80px">
					<el-form-item label="店铺名称">
						<el-input v-model="editForm.name"></el-input>
					</el-form-item>
					<el-form-item label="归属用户">
						<el-select v-model="editForm.user_id" clearable placeholder="类型">
							<el-option v-for="item in storeUserList" :key="item.id" :label="item.username"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="店铺封面">
						<el-upload class="avatar-uploader" action="/admin/upload" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="editForm.images" :src="editForm.images" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div class="el-upload__tip" slot="tip">336*180</div>
						</el-upload>
					</el-form-item>

					<el-form-item label="店铺电话">
						<el-input v-model="editForm.tel"></el-input>
					</el-form-item>

					<el-form-item label="营业时间">
						<el-col :span="11">
							<el-time-picker placeholder="开始时间" value-format="HH:mm:ss" v-model="editForm.start_time"
								style="width: 100%;"></el-time-picker>
						</el-col>

						<el-col :span="11">
							<el-time-picker placeholder="结束时间" value-format="HH:mm:ss" v-model="editForm.end_time"
								style="width: 100%;"></el-time-picker>
						</el-col>
					</el-form-item>
					<el-form-item label="店铺环境">
						<el-upload action="/admin/upload" list-type="picture-card"
							:on-preview="handlePictureCardPreview" :on-remove="handleRemoveMany"
							:on-success="handleSuccessMany" :file-list="fileList" class="avatar-uploader">


							<i class="el-icon-plus avatar-uploader-icon"
								style="width: 150px;height: 150px;line-height: 150px;text-align: center">

							</i>
							<div class="el-upload__tip" slot="tip">351*250</div>


						</el-upload>


					</el-form-item>
					<el-form-item label="店铺介绍">
						<RichText v-model="editForm.introduce"></RichText>
					</el-form-item>

					<el-form-item label="店铺地址">
						<el-input v-model="address" @input="getMap(address)"></el-input>
					</el-form-item>

					<el-form-item label="地图">
						<template>
							<baidu-map class="bm-view" @ready="mapReady" :center="{lng: longitude, lat: latitude}" :zoom="zoomLevel" 
								:scroll-wheel-zoom="true" @click="clickHandler" @zoomend="handleZoom">
								<bm-marker :position="{lng: longitude, lat:latitude}"></bm-marker>
								<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
							</baidu-map>
						</template>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="editing" @click="saveTeacher" style="margin: 10px auto">保存</el-button>

					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="视频上传" :visible="upDialog" width="50%" :before-close="closeMy" v-loading="loadingUp">
			<el-upload class="upload-demo" action="/admin/uploadVideo" ref="uploadVideo" :before-upload="beforeUp"
				:show-file-list="false"
				:data="{is_editor:2}"
				:on-success="(response, file, fileList)=>handleSuccess(response, file, fileList)" :file-list="fileList">
				<el-button size="small" type="primary">点击上传</el-button>
				<!--      <img :src="row.url"  class="avatar" alt="">-->
			</el-upload>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import {
		delToast,
		formVerify
	} from './../../utlis/decorators'
	import RichText from './../../components/RichText'


	export default {
		name: "tenant_account",

		props: {
			parkId: {
				type: [Number, String],
				default: null
			},
		},

		components: {
			RichText
		},
		data() {
			return {
				BMap: '',
				map: '',
				zoomLevel: 15,
				zoomLevel_record: 15,
				//坐标默认在重庆
				latitude: 29.568996, 
				longitude: 106.558434,
				upDialog: false,
				dialogFormVisible: false,
				loading: false,
				currentTop: 0,
				total: 0,
				showAdd: false,
				showEdit: false,
				showEditPsd: false,
				editing: false,
				address: '',
				searchForm: {
					page: 1,
					limit: 10,
					username: ''
				},
				form: {
					nickname: ''
				},
				user_type: '',
				editForm: {
					role_ids: [],
					rule_id: [1, 2],
					nickname: '',
					username: '',
					password: '',
					status: '',
					images: '',
				},
				environment: [],
				currentId: '',
				tableData: [],
				storeUserList: [],
				addTeacher: {
					images: '',
					type_id: 1,
					rule_id: [],
					longitude: '',
					latitude: ''

				},
				dialogImageUrl: '',
				dialogVisible: false,
				shelves: [{
						'key': 1,
						'value': '上架',

					},
					{
						'key': 2,
						'value': '下架',

					},
				],
				changeImg: [],
				fileList: [],


			}
		},
		created() {
			this.storeList();
			this.storeUserLists();
			this.$event.$on('upVideo', () => {
				this.upDialog = true
			})
			this.$event.$on('closeUp', () => {
				this.upDialog = false
			})
			this.user_type = window.sessionStorage.getItem('type')
		},
		computed: {
			getRules() {
				return {
					role_ids: [{
						required: true,
						message: `角色不能为空`,
						trigger: ['blur', 'change']
					}],
					nickname: [{
						required: true,
						message: `昵称不能为空`,
						trigger: ['blur', 'change']
					}],

				}
			}
		},
		watch: {
			'searchForm.page'() {
				this.storeList();
			},
		},
		methods: {
			// 图片上传删除
			handleRemoveMany(file, fileList) {
				this.changeImg = [];
				if (fileList.length <= 0) return
				fileList.forEach((item, index) => {
					this.changeImg.push(item.url)
				})
			},
			// 图片上传编辑
			handleSuccessMany(response, file, fileList) {
				// console.log('状态'+JSON.stringify(response));
				if (response.code == 200) {
					// response.url
					this.changeImg.push(response.url);
				} else {
					this.$message.error('上传失败，请稍后再试！')
				}
			},
			//上传视频成功
			handleSuccess(response, file, fileList) {
				// console.log(file.response.url);
				if (file.status == "success") {
					this.$event.$emit('setVideo', file.response.url)

					// this.$message.success('上传成功！')
				} else {
					this.$message.error('上传失败，请重试！')
				}


			},
			handleRemove(file, fileList) {

				this.environment = [];
				if (fileList.length <= 0) return;
				fileList.forEach((item, index) => {

					this.environment.push(item.response.url)
				})
			},

			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
				console.log(this.dialogImageUrl);
			},
			//视频上传 on-success
			handleChange(file, fileList) {

				console.log(file);
				console.log(this.test);
				if (file.response) {
					if (file.response.code == 400) {
						this.$message.warning(file.response.msg);

					} else {
						console.log(file.response.url);
						console.log(this.environment);

						console.log(this.environment);
						// this.videoName.push(file.response.fileName);
						this.environment.push(file.response.url);
					}

				}
				console.log(this.environment);


			},
			mapReady({
				BMap,
				map
			}) {
				this.BMap = BMap;
				this.map = map;

			},
			//地图
			getMap(address) {
				let geoc = new this.BMap.Geocoder();
				geoc.getPoint(address, (point) => {
					console.log(point);
					if (point) {
						this.longitude = point.lng;
						this.latitude = point.lat;
					} else {
						alert("您选择地址没有解析到结果!");
					}
				}, "重庆市");
			},

			//地图
			//地图
			clickHandler(e) {


				var pt = e.point;
				let geoc = new this.BMap.Geocoder();

				geoc.getLocation(pt, (rs) => {
					console.log(rs);
					var addComp = rs.addressComponents;
					var points = rs.point;

					this.longitude = points.lng;
					this.latitude = points.lat;
					this.address = rs.address;
					if(this.zoomLevel_record>10) {
						this.zoomLevel = this.zoomLevel_record;
					}
					console.log(addComp);

				});
			},
			handleZoom(e) {
				console.log('地图缩放', e.currentTarget.Mc);
				// console.log(e);
				console.log(e.currentTarget.Mc);
				this.zoomLevel_record = e.currentTarget.Mc;
			},

			//关闭
			closeMy() {
				this.upDialog = false
			},
			//头像
			handleAvatarSuccess(res, file) {

				this.editForm.images = res.url;
				this.addTeacher.images = res.url;
				this.$forceUpdate();
				console.log(this.addTeacher.images);
			},

			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				//
				// if (!isJPG) {
				//     this.$message.error('上传头像图片只能是 JPG,png 格式!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isLt2M;
			},
			handShowAdd() {
				this.addTeacher = {
					images: ''
				};
				this.address = '';
				try {
					this.showAdd = true;
					this.$refs['form'].resetFields()
					for (let i in this.addTeacher) {
						this.addTeacher[i] = '';
					}
				} catch {}
				// this.getTeacherstoreUserList(this.addTeacher.type_id);
			},
			handEditShow(row) {

				this.editForm = row;
				this.environment = [];
				this.fileList = [];
				this.showEdit = true;
				this.environment = row.environment;
				this.environment.forEach(item => {
					let obj = {
						name: item,
						url: item
					};
					this.fileList.push(obj)
				})
				// this.dialogVisible = true;
				this.address = row.address;
				this.longitude = row.longitude;
				this.latitude = row.latitude;

			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			handleSelectionChange(val) {
				this.selectData = val;
			},
			//获取列表
			async storeList() {
				let [e, data] = await this.$api.storeList(this.searchForm);
				if (e) return;
				if (data.code === 200) {

					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
			//类型
			async storeUserLists(value) {


				let [e, data] = await this.$api.storeUserLists();
				if (e) return;

				if (data.code === 200) {

					this.storeUserList = data.data;
				}
			},

			@delToast()
			async teacherDel({
				id
			}) {
				let [e, data] = await this.$api.delStore({
					id
				});
				if (e) return;
				if (data.code === 200) {
					this.storeList();
					this.$message.success('删除成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//下载二维码

			async storeCode({
				id
			}) {
				let [e, data] = await this.$api.storeCode({
					id
				});
				if (e) return;
				if (data.code === 200) {
					var a = document.createElement('a')
					a.download = name || 'pic'
					// 设置图片地址
					a.href = data.data;
					a.click();

				} else {
					this.$message.warning(`${data.msg}`)
				}
			},

			//添加
			async addTeachers() {
				this.editing = true;
				this.addTeacher.longitude = this.longitude;
				this.addTeacher.latitude = this.latitude;
				this.addTeacher.address = this.address;
				this.addTeacher.environment = this.environment
				let [e, data] = await this.$api.createStore(this.addTeacher);
				if (e){this.editing = false; return;}
				if (data.code === 200) {
					this.storeList();
					
					this.showAdd = false;

					this.$message.success('添加成功')
					this.editing = false;
				} else {
					this.$message.warning(`${data.msg}`)
					this.editing = false;
				}
			},
			//账号编辑

			async saveTeacher() {
				// console.log(this.changeImg);
				// return
				this.editForm.environment = this.changeImg;
				this.editing = true;
				this.editForm.longitude = this.longitude;
				this.editForm.latitude = this.latitude;
				this.editForm.address = this.address;
				console.log(1111);
				console.log(this.environment);
				let [e, data] = await this.$api.saveStore(this.editForm);
				
				if (e){this.editing = false;return};
				if (data.code === 200) {
					this.showEdit = false;
					this.storeList();
					this.$message.success('保存成功')
					this.editing = false;
				} else {
					this.$message.warning(`${data.msg}`)
					this.editing = false;
				}
			}
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.bm-view {
		width: 590px;
		height: 400px;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
